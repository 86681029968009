.card {
  border-left: 0;
  border-right: 0;
}


.card-header {
  border-bottom: unset;
}

.card-body {
  padding: 0 0 30px 0;
}