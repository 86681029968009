// Project's custom mixins
@mixin style-dots($color-default, $color-active) {

  margin: 0 convert-to-rem(5px);
  padding: convert-to-rem(7px);
  border: 0;
  border-radius: 50%;
  background: $color-default;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &.tns-nav-active {

    background: $color-active;
  }
}

@mixin banner-pages($image) {
  background-image: url($image);
  background-size: cover;
  background-position: center;
}