.team{
  &-filter-image {

    top: 0;
    opacity: .8;

    @include media-breakpoint-up(lg){
      top: 0;
      opacity: 0;
      transition: .5s;
      transition-timing-function: ease;

      &:hover {

        opacity: .8;
        transition-timing-function: ease-out;
      }
    }
  }

  &-read-more {

    bottom: 25px;
    opacity: 1;
    right: 75px;

    &:after{
      content: '';
      background: url("/svg/svg-arrow-right.svg") no-repeat;
      position: absolute;
      width: 36px;
      height: 20px;
      top: 8px;
      right: -46px;
    }
  }

  &-arrow{
    width: 32px;
    height: 30px;
  }
}

.arrow-formation p{
  position: relative;
  padding-left: 40px;

  &:before{
    content: "";
    background: url("/svg/svg-arrow-right.svg") no-repeat;
    position: absolute;
    width: 36px;
    height: 20px;
    left: 0;
    top: 7px;
  }
}