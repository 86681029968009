.slider {

  button[data-nav] {

          @include style-dots(theme-color('secondary'),theme-color('primary'));
        }
}

.tns {

  &-outer {

    position: relative;
  }

  &-nav {
    text-align: center;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 100%;

    @include media-breakpoint-up(lg){
      text-align: right;
      position: absolute;
      width: 100%;
      transform: none;
      z-index: 9;
      right: 10%;
      bottom: 5%;
    }
  }

  &-controls {

    outline: none;

    button[data-controls] {

      border: 0;
      -webkit-appearance: none;
      text-indent: -9999px;
      overflow: hidden;
      background: url('/svg/svg-arrow-indicator.svg') no-repeat center/contain;
      width: convert-to-rem(25px);
      height: convert-to-rem(50px);
      padding: 0;
      position: absolute;
      top: 35%;

      @include media-breakpoint-up(lg){
        top: 40%;
      }

    }

    button[data-controls='prev'] {
      left: -33px;

      @include media-breakpoint-up(lg){
        left: -5%;
      }

    }

    button[data-controls='next'] {
      right: -40px;
      transform: rotate(180deg);

      @include media-breakpoint-up(lg){
        right: -5%;
        transform: rotate(180deg);
      }

    }
  }
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
}

.tns-nav-active{
  background: $info;
}

.tns-controls button:disabled{
  display: none;
}

.customize-toggle{
  display: none;
}