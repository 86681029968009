.footer{

  &-icons-social{
    width: 30px;
    height: 30px;
  }

  &-formulary{
    border-radius: 50px !important;
    padding: 12px 85px 11px 20px!important;
    height: auto !important;
    border: none;
  }

  &-button-form{
    right: 0;
  }

  &-icon-button{
    width: 54px;
    height: 39px;
  }

  &-invalid-feedback{
    bottom: -27px;
    color: white !important;
  }

  &-icons-fixed{
    position: fixed;
    width: 40px;
    height: 40px;
    right: 20px;
  }

  &-facebook{
    bottom: 60%;
    position: fixed;
  }

  &-linkedin{
    bottom: 52%;
    position: fixed;
  }

  &-instagram{
    bottom: 44%;
    position: fixed;
  }
}