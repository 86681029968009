.menu {

  &-formulary{
    border-radius: 50px 0 0 50px !important;
    padding: 10px 20px !important;
    height: auto !important;
    border: none;
    width: 100%;

    @include media-breakpoint-up(lgp){
      width: 120px;
    }

    @include media-breakpoint-up(xl){
      width: 150px;
    }
  }

  &-logo{
    width: 130px;
    height: 28px;
  }

  &-icon-hamburguer{
    width: 28px;
    height: 28px;
  }

  &-desk {
    top: 0;
    right: 0;
    left: 0;
    z-index: 9;
    transition: all 0.5s;
    position: fixed;

    &.scrollUp{
      transform: translateY(-105px)
    }
  }

  &-hamburguer {

    top: convert-to-rem(20px);
    left: convert-to-rem(20px);
  }

  &-fixed {

    top: -100%;
    position: fixed;
    width: 100%;
    transition: top 0.3s ease-in-out;
    border-bottom: 1px solid $secondary;
  }

  &-btn-close {
    right: convert-to-rem(20px);
    top: 0;
  }

  &-title {

    &.active {

      &:after {

        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: convert-to-rem(-2px);
        border: 1px solid $secondary;
        border-left: 0;
        border-right: 0;
        transform: scaleX(1);
      }
    }
  }
}

.navbar {

  &-toggler {
    &:focus {
      outline: none;
    }
  }
}

.collapsing {

  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.3s all ease-in;

  &.width {
    transition-property: width;
    width: 0;
    height: auto;
  }
}

.collapse-left-to-right {
  @include media-breakpoint-down(lgp) {
    background-color: rgba($secondary, .98);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    //right: 0vw;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9;
  }
}

.nav-item {
  @include media-breakpoint-down(lgp) {

    width: 100%;
  }
}

.navbar-nav {

  width: 300px;
  line-height: convert-to-rem(75px);

  @include media-breakpoint-up(xl) {

    width: 100%;
    line-height: 0;
  }
}

.js-menu {

  &-out {

    top: -100%;
  }

  &-in {

    top: 0;
  }
}

.icon-search{
  width: 21px;
  height: 21px;
}

.border-header{
  @include media-breakpoint-up(xl){
    border-left: 1px solid $green-white;
  }
}

.header-logo{
  width: 160px;
  height: 48px;


  @include media-breakpoint-up(xl){
    width: 160px;
    height: 50px;
  }

  @include media-breakpoint-up(xlp){
    width: 220px;
    height: 50px;
  }
}

.close-icon{
  width: 30px;
  height: 30px;
}

.search-form{
  border-radius: 0 50px 50px 0 !important;
}

.backdrop-collapse{
  height: 100vh;
  width: 100%;
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  background-color: $friar;
  opacity: .6;
}

.block-scroll{
  overflow: hidden;
}