// Project's font configuration

// There's 5 generic family groups âserifâ, âsans-serifâ, âcursiveâ, âfantasyâ, and âmonospaceâ

$fonts-base-path: '/fonts';

// Generate @font-face's content body based on a map of font family.
// e.g.: $open-sans-regular: (
//    family: 'open_sansregular',
//    file-name: 'open_sans_regular', -> WITHOUT FONT EXTENSION
//    fallback: sans-serif
//  );
@mixin font-face($font-map) {

  $family: map-get($font-map, 'family');
  $file-name: map-get($font-map, 'file-name');
  //$weight: map-get($font-map, 'weight');
  //$style: map-get($font-map, 'style');

  font-family: '#{$family}';
  src: url('#{$fonts-base-path}/#{$file-name}.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

$example-font-map: (
    family: 'example_family',
    file-name: 'example-family', // WITHOUT FONT EXTENSION
    //fallback: '',
    //weight: '',
    //style: '',
);

$regular: (
    family: 'Montserrat-Regular',
    file-name: 'Montserrat-Regular', // WITHOUT FONT EXTENSION
  //fallback: '',
  //weight: '',
  //style: '',
);

$bold: (
    family: 'Montserrat-Bold',
    file-name: 'Montserrat-Bold', // WITHOUT FONT EXTENSION
  //fallback: '',
  //weight: '',
  //style: '',
);

$extrabold: (
    family: 'Montserrat-ExtraBold',
    file-name: 'Montserrat-ExtraBold', // WITHOUT FONT EXTENSION
  //fallback: '',
  //weight: '',
  //style: '',
);

$medium: (
    family: 'Montserrat-Medium',
    file-name: 'Montserrat-Medium', // WITHOUT FONT EXTENSION
  //fallback: '',
  //weight: '',
  //style: '',
);

$semibold: (
    family: 'Montserrat-SemiBold',
    file-name: 'Montserrat-SemiBold', // WITHOUT FONT EXTENSION
  //fallback: '',
  //weight: '',
  //style: '',
);

$ligt: (
    family: 'Montserrat-Light',
    file-name: 'Montserrat-Light', // WITHOUT FONT EXTENSION
  //fallback: '',
  //weight: '',
  //style: '',
);


$project-fonts: (
    regular: $regular,
    bold: $bold,
    extrabold: $extrabold,
    medium: $medium,
    semibold: $semibold,
    ligt: $ligt,
);

@each $font-key, $font-map in $project-fonts {

  @font-face {

    @include font-face($font-map);
  }
}

@function get-font-family($font-map) {

  @return map-get($font-map, 'family');
}

$font-sizes: (
    12,
    14,
    15,
    16,
    18,
    19,
    20,
    22,
    23,
    24,
    25,
    26,
    30,
    32,
    33,
    35,
    38,
    40,
    45,
    50,
    52,
    56,
    100,
);

$line-height: (
    35,
);

