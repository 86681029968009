.banner-video-content{
  height: 100vh;
  object-fit: cover;
  left: 0;
  top: 0;

  &-title{
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }

  &-icon{
    width: 25px;
    height: 89px;

    @include media-breakpoint-up(lg){
      width: 43px;
      height: 89px;
    }
  }

  &-icon-centralized{
    left: 50%;
    transform: translate(-50%);
    bottom: 5%;

    @include media-breakpoint-up(lg){
      transform: translate(-50%);
      left: 50%;
      bottom: 10%;
    }

  }
}

.function {

  &-slide-title {
    position: absolute;
    bottom: 20px;
    margin-left: 30px;
  }

  &-arrow {
    &::before {
      content: '';
      background: url("/svg/svg-arrow-right.svg") no-repeat;
      position: absolute;
      width: 40px;
      height: 34px;
      top: -40px;
    }
  }
}

.nav-dots {
  background-color: $primary;
  z-index: 9;
}

.icon-down {
  transform: translate(-50%);
  left: 50%;
  bottom: 5%;
}

.arrow-banner {
  width: 27px;
  height: 78px;
  transform: rotate(90deg);
}

.articles {
  &-arrow {
    width: 34px;
    height: 15px;
  }

  &-read-more{
    bottom: 0;
  }
}

.banner-mask {
  @include media-breakpoint-up(md) {
    &:before {
      content: '';
      background: $log-cabin;
      position: absolute;
      opacity: 0.5;
      width: 100%;
      height: 100%;
    }
  }
}