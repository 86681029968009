.dropdown-toggle {
  &::after {
    display: unset;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: url('/svg/svg-arrow-header.svg');
    border-top: unset;
    border-right: unset;
    border-bottom: unset;
    border-left: unset;
  }
}
