.details{
  &-icons-social{
    width: 22px;
    height: 22px;
  }

  &-share-line{
    &:before{
      content: "";
      width: 80px;
      height: 2px;
      background: $primary;
      position: absolute;
      top: 14px;
      left: -100px;
    }

    @include media-breakpoint-up(lg){
      &:before{
        content: "";
        width: 100px;
        height: 2px;
        background: $primary;
        position: absolute;
        top: 14px;
        left: -130px;
      }
    }
  }
}