// Project's common (global) styles

// Creates text classes for all fonts
@each $font-key, $font-map in $project-fonts {

  .#{$font-key}-text {

    font-family: get-font-family($font-map), $fallback-font;
  }
}

// Generate responsive font sizes
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $font-size in $font-sizes {
      .fs#{$infix}-#{$font-size} {
        font-size: #{$font-size}px;
      }
    }
  }
}

//Generate letter-spacing and line-height with grid-breakpoint to use in html
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (letter-spacing: ls, line-height: lh) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }

        @if $size != 0 {
          .#{$abbrev}#{$infix}-n#{$size} {
            #{$prop}: -$length !important;
          }
        }
      }
    }
  }
}

// Generate stroke and fill for each color in theme, including links.
@each $color, $value in $theme-colors {

  .stroke-#{$color} {

    stroke: $value;
  }

  .fill-#{$color} {

    fill: $value;
  }

  @include media-breakpoint-up(md) {

    a {

      @include hover {

        .stroke-#{$color} {

          stroke: darken($value, 15%);
        }

        .fill-#{$color} {

          fill: darken($value, 15%);
        }
      }
    }
  }
}

.title{

  &-opacity{
    opacity: 0.2;
  }

  &-subtitle-center{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
}

.arrow-banner{
  width: 30px;
  height: 34px;
  transform: rotate(90deg);
}

.arrow-articles{
  width: 30px;
  height: 34px;
  transform: rotate(360deg);
}

.contact-banner{
  @include banner-pages("/images/banner-contact-mobile.jpg");

  @include media-breakpoint-up(lg){
    @include banner-pages("/images/banner-contact-desktop.jpg");
  }
}

.article-banner{
  @include banner-pages("/images/banner-articles-mobile.jpg");

  @include media-breakpoint-up(lg){
    @include banner-pages("/images/banner-articles-desktop.jpg");
  }
}

.who-banner{
  @include banner-pages("/images/banner-who-mobile.jpg");

  @include media-breakpoint-up(lg){
    @include banner-pages("/images/banner-who-desktop.jpg");
  }
}

.areas-expertises{
  @include banner-pages("/images/banner-areas-expertises-mobile.jpg");

  @include media-breakpoint-up(lg){
    @include banner-pages("/images/banner-areas-expertises-desktop.jpg");
  }
}

.details-team-banner{
  @include banner-pages("/images/banner-team-mobile.jpg");

  @include media-breakpoint-up(lg){
    @include banner-pages("/images/banner-team-desktop.jpg");
  }
}

.differentials{
  @include banner-pages("/images/banner-differentials-mobile.jpg");

  @include media-breakpoint-up(lg){
    @include banner-pages("/images/banner-differentials-desktop.jpg");
  }
}

.juridic-links{
  @include banner-pages("/images/banner-links-mobile.jpg");

  @include media-breakpoint-up(lg){
    @include banner-pages("/images/banner-links-desktop.jpg");
  }
}

.icon-play{
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 70px;
  height: 78px;
}

.line-image{

  &:before{
    content: '';
    width: 303px;
    height: 10px;
    background: $info;
    position: absolute;
    left: -14%;
    top: 30px;
  }



  @include media-breakpoint-down(lg){
    &:before{
      content: "";
      width: 110px;
      height: 5px;
      background: $info;
      position: absolute;
      bottom: -3px;
      transform: rotate(270deg);
      left: -20px;
      top: unset;
    }
  }
}

.line-image-opposite{

  &:before{
    content: '';
    width: 303px;
    height: 10px;
    background: $info;
    position: absolute;
    right: -17%;
    top: 30px;
  }

  @include media-breakpoint-down(lg){
    &:before{
      content: "";
      width: 110px;
      height: 5px;
      background: $info;
      position: absolute;
      bottom: -3px;
      transform: rotate(270deg);
      left: -20px;
      top: unset;
    }
  }
}

.icon-arrow{
  width: 30px;
  height: 34px;
}

.box-images{
  overflow: hidden;
}

.animation-images{
  transition: transform .5s ease;

  &:hover{
    transform: scale(1.1);
  }
}

.clear-animation-wow{
  overflow: hidden;
}

.custom-cursor{
  cursor: pointer;
}

.icon-whatsapp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  fill: white;
}

.btn-whatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 15px;
  right: 15px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.adjust-description{
  top: 0;
}

.img-cover{

  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.prevent-word-break{

  word-break: break-word;
}