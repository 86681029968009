.juridic{

  &-arrow{
    width: 9px;
    height: 18px;
    top: 50%;
    left: 10px;

  }

  &-title[aria-expanded="true"] {
    color: $danger;
     .juridic-arrow {
      top: 40%;
      transform: rotate(90deg);
      transition: all 0.2s linear;
    }
  }

  &-title[aria-expanded="false"] {
    color: $primary;
    .juridic-arrow {
      transform: translate(-50%, -50%);
      transition: all 0.2s linear;
    }
  }

}